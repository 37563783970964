import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

import Register from "../views/Register.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Cookies from "js-cookie";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});

const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/dashboard");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },
  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
 

  {
    path: "/license-plates",
    name: "license-plates",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LicensePlates.vue"),
    beforeEnter: authGuard,
    meta: { title: "License Plates" },
  },
  {
    path: "/deep-search",
    name: "deep-search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeepSearch.vue"),
    beforeEnter: authGuard,
    meta: { title: "License Plates" },
  },
  
  {
    path: "/bulk-deactivate-lp",
    name: "bulk-deactivate-lp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BulkDeactivate.vue"),
    beforeEnter: authGuard,
    meta: { title: "Deactivate License Plates" },
  },
  
  {
    path: "/license-plates-in-accounts",
    name: "license-plates-in-accounts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LicensePlatesInAccount.vue"),
    beforeEnter: authGuard,
    meta: { title: "License Plates" },
  },
  {
    path: "/license-plates-unique",
    name: "license-plates-unique",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LicensePlatesUnique.vue"),
    beforeEnter: authGuard,
    meta: { title: "License Plates" },
  },
  {
    path: "/states",
    name: "states",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/State.vue"),
    beforeEnter: authGuard,
    meta: { title: "State" },
  },
  {
    path: "/agencies",
    name: "agencies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Agencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/agencies-graphical",
    name: "agencies-graphical",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AgencyGraphical.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/agencies-coverage",
    name: "agencies coverage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AgencyCoverage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/agencies-coverage-details",
    name: "AgencyCoverageDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Coverages.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Clients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients" },
  },
  {
    path: "/accounts-clients",
    name: "ClientsAccounts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ClientsAccounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "ClientsAccounts" },
  },
  {
    path: "/accounts-agency-graphical",
    name: "AgencyGraphicalAccount",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AgencyGraphicalAccount.vue"),
    beforeEnter: authGuard,
    meta: { title: "AgencyGraphicalAccount" },
  },
  {
    path: "/accounts-graphical-accounts",
    name: "AccountsGraphicalAccounts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AccountsGraphicalAccounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "AccountsGraphicalAccounts" },
  },
  {
    path: "/accounts-graphical",
    name: "accounts-graphical",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AccountsGraphical.vue"),
    beforeEnter: authGuard,
    meta: { title: "Accounts" },
  },
  {
    path: "/lp-deactivation-requests",
    name: "Lp Deactivation Requests",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LpDeactivateRequest.vue"
      ),
    beforeEnter: authGuard,
    meta: { title: "Accounts" },
  },
  {
    path: "/lp-add-requests",
    name: "Lp Add Requests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LpAddRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "LpAddRequest" },
  },
  {
    path: "/failed-adds",
    name: "Failed Add Requests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LpAddRequestsFailed.vue"),
    beforeEnter: authGuard,
    meta: { title: "FailedAddRequest" },
  },
  {
    path: "/failed-deactivations",
    name: "Failed Deactivation Requests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LpDeactivateRequestFailed.vue"),
    beforeEnter: authGuard,
    meta: { title: "FailedDeactivationRequest" },
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;

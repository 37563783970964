import gql from "graphql-tag";

//Logout
export const LOGOUT_MUTATION = gql`
  mutation Logoutuser {
    logoutuser {
      userId
      token
      tokenExpiration
    }
  }
`;
//password reset
export const REQUEST_RESETPASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      userId
      username
      email
    }
  }
`;

export const RESETPASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $code: String!
    $userId: String!
    $confirmPassword: String!
    $password: String!
  ) {
    resetPassword(
      code: $code
      userId: $userId
      confirmPassword: $confirmPassword
      password: $password
    ) {
      email
      userId
      username
    }
  }
`;
//STATE
export const ALL_STATES_QUERY = gql`
  query States {
    states {
      state_id
      state_name
      abbreviation
      tax_amount
    }
  }
`;
// dashboard insights 
export const ACCOUNTS_INSIGHTS_QUERY = gql`
 query Query($client: ID) {
  lpsPerAccountInsights(client: $client) {
    label
    value
  }
}
`;
export const LP_PER_AGENCY_INSIGHTS_QUERY = gql`
query LpsPerAgencyInsights($client: ID) {
  lpsPerAgencyInsights(client: $client) {
    label
    value
  }
}
`;

export const ADD_STATE_MUTATION = gql`
  mutation CreateState($input: StateInput) {
    createState(input: $input) {
      abbreviation
      state_name
      tax_amount
    }
  }
`;
export const DELETE_STATE_MUTATION = gql`
  mutation DeleteState($deleteStateId: Int!) {
    deleteState(id: $deleteStateId)
  }
`;
export const EDIT_STATE_MUTATION = gql`
  mutation UpdateState($updateStateId: Int!, $input: StateUpdate) {
    updateState(id: $updateStateId, input: $input) {
      state_id
      state_name
      abbreviation
      tax_amount
    }
  }
`;
//AGENCY
export const ALL_AGENCYS_QUERY = gql`
  query Agencies {
    agencies {
      agency_id
      agency_name
      abbreviation
      site_url
      state_id {
        state_name
        state_id
        abbreviation
      }
    }
  }
`;

export const ADD_AGENCY_MUTATION = gql`
  mutation CreateAgency($input: AgencyInput) {
    createAgency(input: $input) {
      agency_id
      agency_name
      abbreviation
      site_url
      state_id {
        state_name
        state_id
      }
    }
  }
`;
export const ADD_LP_MUTATION = gql`
  mutation Mutation($input: LicensePlateInput!) {
  addLicensePlate(input: $input) {
    id
    license_plate
    start_date
    end_date
    equipment_id
    vehicle_status
    asset_type
    body_type
    branding
    dot_ndot
    fleet_name
    license_plate_country
    life_cycle_status
    life_cycle_status_reason
    make
    model
    color
    year
    operator
    owner
    program
    purchase_type
    rental_status
    vin
    asset_update_date
    vehicle_type
    createdAt
    updatedAt
    client {
      org_email
      mail_address
      organization
      phone
    }
    state {
      state_id
      state_name
      abbreviation
      tax_amount
    }
  }
}
`;
export const UNIQUE_LP_QUERY = gql`
 query LicensePlates($searchParams: String, $limit: Int!, $offset: Int! ) {
  licensePlates(searchParams: $searchParams, limit: $limit, offset: $offset) {
    data {
      id
      license_plate
      start_date
      end_date
      equipment_id
      vehicle_status
      asset_type
      body_type
      branding
      dot_ndot
      fleet_name
      license_plate_country
      life_cycle_status
      life_cycle_status_reason
      make
      model
      color
      year
      operator
      owner
      program
      purchase_type
      rental_status
      vin
      asset_update_date
      vehicle_type
      createdAt
      updatedAt
      client {
        org_email
        organization
      }
      state {
        state_name
        abbreviation
      }
      department {
        dept_email
        dept_name
      }
    }
    count
    filteredCount
  }
}
`;
export const DEACTIVATE_LP_MUTATION = gql`
  mutation Mutation($deactivateLicensePlateId: ID!) {
  deactivateLicensePlate(id: $deactivateLicensePlateId) {
    license_plate
    start_date
  }
}
`;
export const DEACTIVATE_LP_IN_ACCOUNT_MUTATION = gql`
  mutation DeactivateLicensePlateByAccount($deactivateLicensePlateByAccountId: ID!, $account: ID!) {
  deactivateLicensePlateByAccount(id: $deactivateLicensePlateByAccountId, account: $account) {
    id
    license_plate {
      id
      license_plate
      vehicle_status
    }
    agency {
      account_agency_id
      abbreviation
    }
    transponder_number
    transponder_id
    account {
      id
      account_name
    }
    start_date
    end_date
    processing_status
    comments
    createdAt
    updatedAt
  }
}
`;
export const MARK_LP_ADDED_MUTATION = gql`
  mutation MarkLicensePlateAsAdded($markLicensePlateAsAddedId: ID!, $account: ID!) {
    markLicensePlateAsAdded(id: $markLicensePlateAsAddedId, account: $account) {
      id
    }
  }
`;
export const MARK_LPs_ADDED_MUTATION = gql`
  mutation MarkLPsAsAdded($account: ID!, $markLPsAsAddedId: [ID!]) {
  markLPsAsAdded(account: $account, id: $markLPsAsAddedId)
}
`;
export const MARK_LP_DEACTIVATED_MUTATION = gql`
  mutation Mutation($markLicensePlateAsDeactivatedId: ID!) {
  markLicensePlateAsDeactivated(id: $markLicensePlateAsDeactivatedId) {
    license_plate {
      license_plate
      end_date
    }
  }
}
`;
export const FAILED_ADD_LP_MUTATION = gql`
  mutation AddLpComments($addLpCommentsId: ID!, $comments: String!) {
  addLpComments(id: $addLpCommentsId, comments: $comments) {
    id
  }
}
`;
export const DELETE_AGENCY_MUTATION = gql`
  mutation DeleteAgency($deleteAgencyId: Int!) {
    deleteAgency(id: $deleteAgencyId)
  }
`;
export const EDIT_AGENCY_MUTATION = gql`
  mutation UpdateAgency($updateAgencyId: Int!, $input: updateagency) {
    updateAgency(id: $updateAgencyId, input: $input) {
      state_id {
        tax_amount
        state_name
        state_id
        abbreviation
      }
      site_url
      agency_name
      agency_id
      abbreviation
    }
  }
`;
//CATEGORIES
export const ALL_CATEGORIES_QUERY = gql`
  query FileCategories {
    fileCategories {
      file_category_id
      file_category_name
      editable
    }
  }
`;
export const ADD_CATEGORY_MUTATION = gql`
  mutation CreateFileCategory($input: FileCategoryInput) {
    createFileCategory(input: $input) {
      file_category_id
      file_category_name
      editable
    }
  }
`;
export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
export const EDIT_CATEGORY_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
//User
export const ALL_USER_QUERY = gql`
  query Employees {
    employees {
      first_name
      last_name
      id
      initials
    }
  }
`;

export const ADD_USER_MUTATION = gql`
  mutation CreateUser($createAcc: createuser) {
    createUser(createAcc: $createAcc) {
      user_id
      first_name
      last_name
    }
  }
`;
export const CURRENT_USER_MUTATION = gql`
  query CurrentUser {
    currentUser {
      user_id
      first_name
      last_name
      username
      email
      phone_number

      role {
        group_id {
          group_name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
export const LOGIN_USER_MUTATION = gql`
  mutation LoginEmployee($username: String!, $password: String!) {
    loginEmployee(username: $username, password: $password) {
      authData {
        token
      }
      employee {
        id
        first_name
        last_name
        phone_number
        title
        address
        initials
      }
      user {
        role {
          id
          group_id {
            group_id
            group_name
          }
        }
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
export const UPDATE_USER = gql`
  mutation Updateuser($updateuserId: Int!, $input: UserInput) {
    updateuser(id: $updateuserId, input: $input) {
      user_id
      first_name
      last_name
      username
      email
      date_of_birth
      gender
      location
      profile_picture
      phone_number
    }
  }
`;
// Agencies
export const ACCOUNT_AGENCY = gql`
  query AccountAgencies {
    accountAgencies {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;
export const AGENCY_ACCOUNT = gql`
  query AgencyAccounts($filter: agencyAccountFilter) {
    agencyAccounts(filter: $filter) {
      id
      account_name
      account_number
      password
      agency {
        abbreviation
        agency_name
        account_agency_id
      }
      client {
        id
        organization
      }
      countOfLps
    }
  }
`;

export const UPDATE_ACCOUNT_AGENCY_MUTATION = gql`
  mutation UpdateAccountAgency(
    $updateAccountAgencyId: Int!
    $input: AccountAgencyInput
  ) {
    updateAccountAgency(id: $updateAccountAgencyId, input: $input) {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const CREATE_ACCOUNT_AGENCY_MUTATION = gql`
  mutation CreateAccountAgency($input: AccountAgencyInput) {
    createAccountAgency(input: $input) {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const DELETE_ACCOUNT_AGENCY_MUTATION = gql`
  mutation DeleteAccountAgency($deleteAccountAgencyId: Int!) {
    deleteAccountAgency(id: $deleteAccountAgencyId)
  }
`;
// server side agencies
export const ALL_SERVER_SIDE_AGENCIES = gql`
query AccountAgenciesSSR($limit: Int!, $offset: Int!, $clientId: ID, $searchParams: String) {
  accountAgenciesSSR(limit: $limit, offset: $offset, clientId: $clientId, searchParams: $searchParams) {
    data {
      account_agency_id
      agency_name
      abbreviation
      site_url
      countOfAccounts
      createdAt
      updatedAt
    }
    count
  }
}
 
`;
// Accounts
export const UPDATE_AGENCY_ACCOUNT_MUTATION = gql`
  mutation UpdateAgencyAccount(
    $updateAgencyAccountId: Int!
    $input: AgencyAccountInput
  ) {
    updateAgencyAccount(id: $updateAgencyAccountId, input: $input) {
      id
      account_name
      account_number
      password
      client {
        id
        organization
      }
      agency {
        account_agency_id
        agency_name
        abbreviation
      }
    }
  }
`;

export const CREATE_AGENCY_ACCOUNT_MUTATION = gql`
  mutation CreateAgencyAccount($input: AgencyAccountInput) {
    createAgencyAccount(input: $input) {
      id
      account_name
      account_number
      password
      client {
        id
        organization
      }
      agency {
        abbreviation
        account_agency_id
        agency_name
      }
    }
  }
`;
// server side accounts
export const ALL_SERVER_SIDE_ACCOUNTS = gql`
 query AgencyAccountsServerSide($limit: Int!, $offset: Int!, $filter: agencyAccountFilter, $searchParams: String) {
  agencyAccountsServerSide(limit: $limit, offset: $offset, filter: $filter, searchParams: $searchParams) {
    data {
      id
      account_name
      account_number
      password
      agency {
        agency_name
        abbreviation
        account_agency_id
      }
      client {
        id
        organization
      }
      countOfLps
      createdAt
      updatedAt
    }
    count
  }
}
`;
export const DELETE_AGENCY_ACCOUNT_MUTATION = gql`
  mutation DeleteAgencyAccount($deleteAgencyAccountId: Int!) {
    deleteAgencyAccount(id: $deleteAgencyAccountId)
  }
`;
// Coverage
export const UPDATE_AGENCY_COVERAGE_MUTATION = gql`
 mutation UpdateAccountAgencyCoverage($updateAccountAgencyCoverageId: Int!, $input: AccountAgencyCoverageInput) {
  updateAccountAgencyCoverage(id: $updateAccountAgencyCoverageId, input: $input) {
    account_agency_coverage_id
  }
}
`;
export const CREATE_AGENCY_COVERAGE_MUTATION = gql`
  mutation Mutation($accountAgencyId: ID!, $agencyId: [ID!]!) {
    createAccountAgencyCoverage(
      account_agency_id: $accountAgencyId
      agency_id: $agencyId
    ) {
      account_agency_coverage_id
    }
  }
`;

export const DELETE_AGENCY_COVERAGE_MUTATION = gql`
  mutation DeleteAccountAgencyCoverage($deleteAccountAgencyCoverageId: Int!) {
    deleteAccountAgencyCoverage(id: $deleteAccountAgencyCoverageId)
  }
`;

export const ALL_ACCOUNT_COVERAGE_QUERY = gql`
  query AccountAgencyCoverages {
    accountAgencyCoverages {
      account_agency_coverage_id
      account_agency_id {
        abbreviation
        account_agency_id
        agency_name
      }
      agency_id {
        abbreviation
        agency_id
        agency_name
      }
    }
  }
`;
//fetch single coverage
export const SINGLE_ACCOUNT_COVERAGE_QUERY = gql`
  query AccountAgencyCoverage($coverageId: Int!) {
  accountAgencyCoverage(coverageId: $coverageId) {
    account_agency_coverage_id
    account_agency_id {
      account_agency_id
      agency_name
    }
    agency_id {
      agency_id
      agency_name
    }
  }
}
`;
export const ASSETS_IN_AGENCY_ACCOUNTS = gql`
  query LicensePlateInformations(
    $limit: Int!
    $offset: Int!
    $filter: LicenseInformationFilter
    $lpFilter: licensePlateFilter
    $searchParams: String
    $orderBy: [[String]]
  ) {
    licensePlateInformations(
      limit: $limit
      offset: $offset
      filter: $filter
      lpFilter: $lpFilter
      searchParams: $searchParams
      orderBy: $orderBy
    ) {
      count
      filteredCount
      data {
        id
        license_plate {
          id
          client {
            id
            organization
          }
          department {
            dept_name
            dept_email
          }
          license_plate
          start_date
          end_date
          equipment_id
          vehicle_status
          asset_type
          body_type
          branding
          dot_ndot
          fleet_name
          license_plate_country
          life_cycle_status
          life_cycle_status_reason
          make
          year
          model
          color
          operator
          owner
          program
          purchase_type
          rental_status
          vin
          asset_update_date
          vehicle_type
          createdAt
          updatedAt
          state {
            state_name
            abbreviation
          }
        }
        agency {
          agency_name
           account_agency_id
        }
        transponder_number
        transponder_id
        account {
          account_number
          account_name
          id
        }
        start_date
        end_date
        processing_status
        comments
        createdAt
        updatedAt
      }
    }
  }
`;

export const ALL_CLIENT_FILES_QUERY = gql`
  query ClientFiles($fileFilter: fileFilter, $offset: Int!, $limit: Int!) {
    clientFiles(fileFilter: $fileFilter, offset: $offset, limit: $limit) {
      file_id
      client_id {
        organization
        phone
        org_email
      }
      original_name
      asset_file_name
      category_id {
        file_category_name
        file_category_id
      }
      upload_month
      upload_datetime
      status
      createdAt
      updatedAt
    }
  }
`;
// Clients
export const ALL_CLIENTS_QUERY = gql`
  query Clients($filter: clientFilter, $offset: Int, $limit: Int) {
    clients(filter: $filter, offset: $offset, limit: $limit) {
      id
      address
      mail_address
      postal_code
      phone
      org_email
      sec_email
      organization
      po_box
      client_status
    }
  }
`;

//server side clients
export const ALL_SERVER_SIDE_CLIENTS = gql`
  query ClientsServerSide($offset: Int!, $limit: Int!, $searchParams: String) {
    clientsServerSide(offset: $offset, limit: $limit, searchParams: $searchParams) {
      count
      data {
        id
        address
        mail_address
        postal_code
        phone
        org_email
        organization
        createdAt
        updatedAt
      }
  }
}
`;

export const ADD_CLIENT_MUTATION = gql`
  mutation AddClient($input: ClientsInput) {
    addClient(input: $input) {
      id
      address
      phone
      org_email
      organization
    }
  }
`;
// Unique Lps
export const ALL_UNIQUE_LP_QUERY = gql`
  query LicensePlates($searchParams: String, $limit: Int!, $offset: Int!) {
  licensePlates(searchParams: $searchParams, limit: $limit, offset: $offset) {
    count
    data {
      license_plate
    }
  }
}
`;
// dashboard insights
export const DASHBOARD_INSIGHTS = gql`
  query Query {
  LPDashboardInsights {
    totalLps
    totalClients
    totalAccountsAgency
    totalAccounts
  }
}
`;
// fetch departments
export const ALL_DEPARTMENTS_QUERY = gql`
 query Departments($filter: departmentFilter) {
  departments(filter: $filter) {
    id
    dept_address
    dept_phone
    dept_email
    dept_name
    dept_status
    client {
      id
      organization
    }
    createdAt
    updatedAt
  }
}
`;